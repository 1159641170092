<template>
  <div class="search wrapper-row wrapper-padding">
    <h1>Suche nach "{{ query }}"</h1>
    <b-form @submit.prevent="onSubmit">
      <b-input type="text" v-model="query" placeholder="Suche"></b-input>

      <button variant="primary" type="submit">
        <svg
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 31.1 31.1"
          style="enable-background:new 0 0 31.1 31.1;"
          xml:space="preserve"
        >
              <path
                class="st0"
                d="M28.5,28.1L22,21.6c3.6-4.3,3.4-10.8-0.7-14.8C17,2.5,10.1,2.5,5.8,6.8c-4.3,4.3-4.3,11.3,0,15.5
	c2.1,2.1,5,3.2,7.8,3.2c2.5,0,5-0.9,7-2.5l6.5,6.5L28.5,28.1z M7.2,20.9c-3.5-3.5-3.5-9.2,0-12.7c1.8-1.8,4.1-2.6,6.4-2.6
	c2.3,0,4.6,0.9,6.4,2.6c3.5,3.5,3.5,9.2,0,12.7C16.4,24.4,10.7,24.4,7.2,20.9z"
              />
            </svg>
      </button>
    </b-form>
    <div class="results empty" v-if="results === null">
      <p>Ihre Suche nach "{{ query }}" lieferte leider keine Ergebnisse</p>
    </div>
    <div class="results" v-if="results !== null">
      <div class="result-item" v-for="(result, key) in results" :key="key">
        <router-link :to="result.url">{{ result.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { EtagenApi } from "@/etagen-api";

export default {
  name: "Search",
  props: {
    q: String
  },
  metaInfo() {
    const title = this.meta.title;
    return { title: title };
  },
  data() {
    return {
      results: null,
      query: "",
      meta: {
        title: ""
      }
    };
  },
  watch: {
    query: "doSearch",
    q: "doSearch"
  },
  methods: {
    doSearch() {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set("suche", this.query);

      //window.location.search = urlParams;
      EtagenApi.callEndpoint(`search?query=${this.query}`)
        .then(result => {
          //console.debug(result);
          if (result.data !== undefined && result.data.results !== undefined) {
            this.results = result.data.results;
          } else {
            this.results = null;
            //console.error(result);
          }
        })
        .catch(() => {
          this.results = null;
          //console.error(error);
        });
    },
    fetchResults() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.meta.title = `Suche nach "${this.query}"`;

      // eslint-disable-next-line vue/no-async-in-computed-properties
      this.doSearch(this.query);

      return 0;
    },
    onSubmit(event) {
      event.preventDefault();
    }
  },
  computed: {
    extractQuery() {
      let search;

      search = window.location.search;
      if (search.length) {
        search = search.substr(1);
        if (search.match(/suche=/gi)) {
          search = search.replace(/suche=/g, "");
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.query = search;
          //console.log(search);
        }
      }

      return search;
    }
  },
  created() {
    if (window.localStorage.getItem("search") !== undefined && window.localStorage.getItem("search") !== null) {
      let query = window.localStorage.getItem("search");
      window.localStorage.removeItem("search");

      this.query = query;
      //console.log(query);
    }
    //console.log(this.$route.query);
    if (typeof this.$route.query === "object" && this.$route.query.suche !== undefined) {
      let query = this.$route.query.suche;
      this.query = query;
      //console.log(query);
    }
  }
};
</script>

<style scoped lang="scss">
.search {
  padding-bottom: 2em;
  form {
    position: relative;
    .st0 {
      fill: #676767;
    }
    align-items: center;
    input {
      background: #171717;
      border: none;
      height: 3em;
      &:focus {
        box-shadow: 0 0 0 0.1rem rgb(255 255 255);
      }
    }
    button {
      background: transparent;
      border: none;
      position: absolute;
      right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0.5rem;
      &:focus {
        outline: none;
      }
      svg {
        width: 1.3em;
      }
    }
  }

  .results {
    padding-top: 2rem;

    .result-item {
      padding: 0.5rem 0;

      a {
        color: #676767;
        transition: all 0.3s;
        &:hover {
          text-decoration: none;
          color: #ffec41;
          transition: all 0.3s;
        }
      }
    }
  }
}
</style>
